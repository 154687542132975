.portfolio-container {
  text-align: center;
  background-color: #ffa07a;
  background-size: cover;
  height: 100vh;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .portfolio-container {
    font-size: 1.5rem;
  }
}

.card-deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.btn-primary:hover {
  background-color: #ff905c;
}
.my-card-body {
  background-color: white;
  color: white;
}