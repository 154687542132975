.nav-link {
  margin-right: 20px;
}

.nav-link {
  font-weight: bold;
  color: #333;
  text-decoration: none;
  padding: 8px 16px;
}

.nav-link:hover {
  color: #fff;
  background-color: #333;
}

