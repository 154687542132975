.contact-container {
  text-align: center;
  background-color: #ffa07a;
  background-size: cover;
  height: 100vh;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

.card {
  margin-bottom: 20px;
}
