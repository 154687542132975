.about-page {
  text-align: center;
  background-color: #ffa07a;
  background-size: cover;
  min-height: 100vh;
  position: relative;
}

.card {
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  color: black;
  position: relative;
  z-index: 1;
}

.about-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
}

.card img {
  width: 100%;
  height: auto;
}

.about-page:before {
  content: "";
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

  