.services {
  text-align: center;
  background-color: #ffa07a;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

.services > h1 {
  margin-top: 50px;
}

.services > .row {
  flex: 1;
  overflow-y: auto;
  margin: 50px 0;
}

.card {
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  color: black;
}

.card:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.card-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
}
