body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.about-page {
  text-align: center;
  background-color: #ffa07a;
  background-size: cover;
  min-height: 100vh;
  position: relative;
}

.card {
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  color: black;
  position: relative;
  z-index: 1;
}

.about-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
}

.card img {
  width: 100%;
  height: auto;
}

.about-page:before {
  content: "";
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

  
.contact-container {
  text-align: center;
  background-color: #ffa07a;
  background-size: cover;
  height: 100vh;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

.card {
  margin-bottom: 20px;
}

.services {
  text-align: center;
  background-color: #ffa07a;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

.services > h1 {
  margin-top: 50px;
}

.services > .row {
  flex: 1 1;
  overflow-y: auto;
  margin: 50px 0;
}

.card {
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  color: black;
}

.card:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.card-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
}

.portfolio-container {
  text-align: center;
  background-color: #ffa07a;
  background-size: cover;
  height: 100vh;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .portfolio-container {
    font-size: 1.5rem;
  }
}

.card-deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.btn-primary:hover {
  background-color: #ff905c;
}
.my-card-body {
  background-color: white;
  color: white;
}
.nav-link {
  margin-right: 20px;
}

.nav-link {
  font-weight: bold;
  color: #333;
  text-decoration: none;
  padding: 8px 16px;
}

.nav-link:hover {
  color: #fff;
  background-color: #333;
}


